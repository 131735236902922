<template>
    <div id="faq-page">
        <div class="vx-row">

            <div class="vx-col tw-w-full md:tw-mt-0">
                <vs-collapse accordion type="margin" class="p-0">
                    <vs-collapse-item
                        v-for="(faq, index) in getFaqItems()"
                        class="faq-bg rounded-lg"
                        :class="{'mt-0': !index}"
                        :key="index"
                    >
                        <div slot="header">
                            <h5>{{ faq.question }}</h5>
                        </div>
                        <div v-html="faq.answer"></div>
                    </vs-collapse-item>
                </vs-collapse>

            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'

import Roles from '@/constants/roles'

export default{
    methods: {
        getUserRole () {
            return _.get(this.$store, 'state.dataUser.user.role', '')
        },
        // Group A for EhpadManager and EhpadEmployee roles
        getAGroupFaqItems () {
            return [
                {
                    question: `Happydom, qu’est-ce que c’est ?`,
                    answer: `Happydom est une plateforme de bien-être et de coordination mise à la disposition des familles et des tuteurs pour pouvoir répondre aux besoins et aux envies du résident, grâce à une liste de produits et de services adaptés aux seniors.`
                },
                {
                    question: `Quels sont les avantages d’happydom ?`,
                    answer: `Grâce à happydom :<br />
                    - La famille / le tuteur peut choisir des produits et/ou des services pour le confort de son proche / préposé ;<br />
                    - Vous pouvez visualiser ce que la famille / le tuteur a prévu pour le résident sur le mois en cours ou ceux à venir ;<br />
                    - Vous pouvez également communiquer avec la famille / le tuteur pour lui suggérer des produits ou des services ou bien l’informer si une commande est incomplète.`
                },
                {
                    question: `Quelle est la différence entre le mode d’achat “une seule fois” et “abonnement” ?`,
                    answer: `En sélectionnant le mode d’achat “Une seule fois” sur la fiche d’un produit ou d’un service, dans le catalogue, le référent commandera le produit concerné de manière ponctuelle.<br />
                    Le résident recevra donc le produit ou service une seule fois.<br />
                    En choisissant le mode d’achat “Abonnement”,  un bouton "Fréquence” s'affiche. Le référent va pouvoir choisir de faire livrer plusieurs fois ce produit au résident dans l’année : tous les mois, tous les deux mois …<br />
                    Il n’y a aucun engagement et le référent pourra à tout moment choisir de modifier cet abonnement ou de le supprimer.
                    Il sera prélevé sur le moyen de paiement choisi une fois la livraison effectuée.`
                },
                {
                    question: `J’accueille un nouveau résident. Quelles sont les actions à mener pour lui créer un compte ?`,
                    answer: `Lors de l’arrivée d’un résident, voici les actions à mener :<br /><br />
                    1. Collecter les informations :<br />
                    &nbsp;&nbsp;a. Pour le résident : nom,
                    prénom et numéro de chambre.<br />
                    &nbsp;&nbsp;b. Pour la famille / le tuteur
                    associé : nom, prénom, numéro de
                    téléphone et adresse email.<br /><br />
                    Ces informations sont indispensables pour
                    créer un compte.<br /><br />
                    2. Créer son profil sur happydom en cliquant
                    sur “+ Nouveau” en page d'accueil.<br />
                    3. S'assurer que la personne ait bien créé son compte dans les 15 premiers jours et proposer son aide pour programmer des livraisons.`
                },
                {
                    question: `Comment sont gérées les prestations
                    et les commandes de produits ?`,
                    answer: `Chaque mois, pour les commandes récurrentes, le partenaire reçoit un email lui indiquant les prestations à réaliser et les produits à livrer sur le mois en cours. Le partenaire a alors 5 jours calendaires pour valider les demandes. Au-delà, ces dernières seront considérées comme refusées.<br />
                    Les commandes de produits sont alors livrées
                    une fois par mois à l’établissement.<br /><br />
                    Pour les prestations de service, le partenaire concerné prend contact directement avec l’établissement afin de définir un jour et une heure de réalisation compatibles avec ce dernier.<br />
                    Le partenaire valide au fil de l’eau les
                    prestations réalisées et les produits
                    envoyés, directement sur son interface.`
                },
                {

                    question: `Comment sont payées les
                    prestations ? Où trouver les factures ?`,
                    answer: `L’ensemble des prestations est payé par prélèvement ou carte bancaire, automatiquement. <br />
                    Si une des prestations n’a pas pu se faire, elle ne sera pas prélevée.<br />
                   Le prestataire valide au fil de l’eau la réalisation des prestations, directement sur son interface. Une fois que l’ensemble des prestations ont été honorées, une facture est disponible sur la fiche du résident, dans la rubrique « Commandes ». `
                },
                {
                    question: `Comment prévenir happytal dans
                    le cas du départ d’un résident de
                    l’établissement ?`,
                    answer: `En cas de départ ou de décès
                    d’un résident, contactez happytal au
                    <a href="tel:0186760598" target="_blank">01 86 76 05 98</a> ou
                    par mail à <a href="mailto:relation.client@happytal.com" target="_blank">relation.client@happytal.com</a>.`
                },
                {
                    question: `Qui dois-je contacter si je rencontre un problème technique ? `,
                    answer: `En cas de problème technique, vous pouvez cliquer sur le lien suivant pour obtenir une assistance :
                    <a href="https://happytal.atlassian.net/servicedesk/customer/portal/8/group/10/create/86" target="_blank">Assistance technique</a>.`
                },
                {
                    question: `Qui dois-je contacter pour tout autre question / problème ?`,
                    answer: `Pour tout autre problème (contestation, produit défectueux, exprimer votre niveau de satisfaction, …) vous pouvez contacter happytal au
                    <a href="tel:0186760598" target="_blank">01 86 76 05 98</a> ou
                    par mail à <a href="mailto:relation.client@happytal.com" target="_blank">relation.client@happytal.com</a>.`
                },
                {
                    question: `Accès aux CGV / CGU.`,
                    answer: `Cliquez sur ce lien pour voir les CGU : <a href="https://www.happydom.fr/legal/cgu" target="_blank">Conditions Générales d’Utilisation</a>.<br />
                    Cliquez sur ce lien pour voir les CGV : <a href="https://www.happydom.fr/legal/cgv" target="_blank">Conditions Générales de Vente</a>.`
                },
            ]
        },
        // Group B for Tutor, Family,
        // HappytalEmployee and HappytalSupport roles
        getBGroupFaqItems () {
            return [
                {
                    question: `Happydom, qu’est-ce que c’est ?`,
                    answer: `Happydom est une plateforme de bien-être
                    et de coordination mise à la disposition des
                    familles et des tuteurs pour pouvoir répondre
                    aux besoins et aux envies du résident, grâce
                    à une liste de produits et de services
                    adaptés aux seniors.`
                },
                {
                    question: `Quels sont les avantages d’happydom ?`,
                    answer: `Grâce à happydom :<br />
                    -	Vous pouvez choisir des produits et/ou
                    des services pour le confort de votre
                    proche / préposé<br />
                    -	Vous pouvez visualiser ce que vous
                    avez prévu pour votre aîné sur le mois
                    en cours ou ceux à venir<br />
                    -	L’établissement pourra également vous
                    proposer des produits ou des services ou
                    vous informer si un produit manque à une
                    commande.`
                },
                {
                    question: `Quelle est la différence entre le mode d’achat “une seule fois” et “abonnement” ?`,
                    answer: `En sélectionnant le mode d’achat “Une seule fois” sur la fiche d’un produit ou d’un service, dans le catalogue, vous commanderez le produit concerné pour un achat unique. Votre aîné ne recevra donc ledit produit ou service qu’une seule fois.<br /><br />
                    En choisissant le mode d’achat “Abonnement”,  un bouton "Fréquence” s'affiche. Vous allez pouvoir choisir de faire livrer plusieurs fois ce produit à votre aîné dans l’année : tous les mois, tous les deux mois … jusqu’à 12 fois dans l’année. Il n’y a aucun engagement et vous pourrez à tout moment choisir de modifier cet abonnement ou de le supprimer.
                    Vous serez prélevé sur le moyen de paiement choisi une fois la livraison effectuée.`
                },
                {
                    question: `Comment créer un abonnement ? Comment modifier un abonnement ?`,
                    answer: `Pour créer un abonnement, ou ajouter un produit à votre abonnement existant, il vous suffit de cliquer sur l’onglet  “Catalogue”, puis de choisir la catégorie du produit ou de service dont votre aîné a besoin (Alimentaire, Hygiène & beauté, Divertissement …) Sélectionnez ensuite le produit ou service souhaité. Quand la fiche du produit ou du service apparaît, sélectionnez le mode d’achat “Abonnement”, puis déterminez la fréquence de livraison idéale pour votre proche (tous les mois, tous les 2 mois …).<br /><br />
                    Pour modifier un abonnement, rendez-vous dans l’onglet “Abonnements” et “Récapitulatif”, puis cliquez sur “Modifier”. Vous pourrez alors changer la fréquence de livraison, la quantité ou supprimer un abonnement.`
                },
                {
                    question: `Comment sont gérées les prestations et les commandes de produits ? Que faire si je constate qu’une prestation n’a pas été effectuée ou qu’il manque un produit ? `,
                    answer: `Chaque mois, pour les commandes récurrentes, le partenaire reçoit un email lui indiquant les prestations à réaliser et les produits à livrer sur le mois en cours. Le partenaire a alors 5 jours calendaires pour valider les demandes. Au-delà, ces dernières seront considérées comme refusées.<br />
                    Les commandes de produits sont livrées une fois par mois à l’attention de l’établissement.<br />
                    Pour les prestations de service, le partenaire concerné prend contact directement avec l’établissement afin de définir un jour et une heure de réalisation compatibles avec ce dernier.<br />
                    Le partenaire valide au fil de l’eau les prestations réalisées et les produits envoyés, directement sur son interface.<br />
                    Si un produit manque à votre commande,
                    ou est défectueux contactez directement happytal au
                    <a href="tel:0186760598" target="_blank">01 86 76 05 98</a> ou
                    par mail à <a href="mailto:relation.client@happytal.com" target="_blank">relation.client@happytal.com</a>.`
                },
                {
                    question: `Comment se déclenche le prélèvement ?`,
                    answer: `Le prestataire valide au fil de l’eau la livraison des produits et la réalisation des prestations, directement sur son interface. Une fois que l’ensemble des commandes du mois ont été honorées, une facture est éditée et disponible dans la rubrique « Commandes »  de votre compte happydom. Vous serez alors débité du montant indiqué, via le moyen de paiement enregistré.<br />
                    Si une des prestations n’a pas pu se faire,
                    elle ne sera pas prélevée.`
                },
                {
                    question: `Où trouver les factures ?`,
                    answer: `Les factures sont disponibles dans l’onglet
                    « Commandes ».`
                },
                 {
                    question: `Qui dois-je contacter si je rencontre un problème technique ? `,
                    answer: `En cas de problème technique, vous pouvez cliquer sur le lien suivant pour obtenir une assistance :
                    <a href="https://happytal.atlassian.net/servicedesk/customer/portal/8/group/10/create/86" target="_blank">Assistance technique</a>.`
                },
                {
                    question: `Qui dois-je contacter pour tout autre question / problème ?`,
                    answer: `Pour tout autre problème (contestation, produit défectueux, exprimer votre niveau de satisfaction, …) vous pouvez contacter happytal au
                    <a href="tel:0186760598" target="_blank">01 86 76 05 98</a> ou
                    par mail à <a href="mailto:relation.client@happytal.com" target="_blank">relation.client@happytal.com</a>.`
                },
                {
                    question: `Accès aux CGV / CGU.`,
                    answer: `Cliquez sur ce lien pour voir les CGU : <a href="https://www.happydom.fr/cgu" target="_blank">Conditions Générales d’Utilisation</a>.<br />
                    Cliquez sur ce lien pour voir les CGV : <a href="https://www.happydom.fr/legal/cgv" target="_blank">Conditions Générales de Vente</a>.`
                },
            ]
        },
        // Group C for Provider and HappytalProvider roles
        getCGroupFaqItems () {
            return [
                {
                    question: `Qu’est-ce qu’happydom ?
                    Quels sont ses avantages ?`,
                    answer: `Happydom est une plateforme digitale à
                    destination des résidents et qui a pour objectif
                    de simplifier le quotidien des tuteurs et
                    des familles et d'améliorer la qualité de
                    vie des personnes âgées en EHPAD.<br />
                    Sur cette plateforme se trouve un large
                    choix de produits et de services adaptés
                    aux séniors (bien-être, courses de produits
                    d’hygiène ou alimentaire, divertissement...)
                    que les familles et les tuteurs peuvent
                    commander grâce à une gestion à distance
                    simplifiée : passer les commandes directement
                    sur la plateforme, choisir la récurrence
                    (tous les mois, les deux mois, etc.), être
                    prélevés automatiquement et suivre les
                    commandes (réception des produits commandés
                    ou exécution des prestations).`
                },
                {
                    question: `Qu’est-ce que le PCP ?`,
                    answer: `Le Plan de Confort Personnalisé
                    (PCP) s’inscrit dans le projet de vie du
                    résident. Il permet de répondre à ses
                    besoins pour améliorer son confort au
                    quotidien (gel douche, rdv pédicure.)
                    et de déterminer à quelle fréquence cela
                    doit-être remis ou fait.<br />
                    Le PCP est créé par le proche
                    ou le tuteur. `
                },
                {
                    question: `Concrètement, comment cela se
                    passe-t-il pour moi ?`,
                    answer: `A. Chaque 1er jour du mois, vous
                    recevez un mail vous invitant à vous
                    connecter pour voir les prestations à
                    réaliser dans le mois ou les produits à
                    livrer pour l’ensemble des résidents.<br />
                    B. Pour chaque commande vous devez :<br />
                    •	Contactez le référent de la maison de
                    retraite, dont les coordonnées vous ont
                    été transmises, pour fixer la date et
                    l’heure de livraison du produit, de retrait
                    ou de réalisation de la prestation ;<br />
                    •	Lorsque vous avez fixé la date, passez la
                    commande au statut « <b>commandé / rdv pris</b> » ;<br />
                    •	Lorsque vous avez effectué la prestation
                    / livré la commande, passez la commande au
                    statut « <b>livré</b> ».<br />
                    <b>/!\\ Attention, si vous ne passez pas la
                    commande en « livré », le résident ne sera
                    pas débité et nous ne pourrons pas
                    vous régler.</b><br />
                    C. Chaque fin de mois, vous recevrez un
                    email vous rappelant de mettre à jour
                    le statut des commandes.`
                },
                {
                    question: `Comment changer le statut
                    d’une commande d’un résident ?`,
                    answer: `Il vous suffit de sélectionner
                    le résident en cochant la case à gauche,
                    puis de choisir le statut souhaité dans
                    le menu déroulant « <b>ACTIONS</b> ».<br />
                    Nb : L’action « <b>Livrée</b> » est le
                    statut à choisir pour indiquer que la
                    prestation a été faite.<br />
                    Le client n’est prélevé que lorsque le
                    statut de la commande est en « <b>Livrée</b> ».`
                },
                {
                    question: `A qui dois-je envoyer ma facture ?
                    Sous quel format ? À quelle fréquence ?`,
                    answer: `Avant le 3 du mois suivant,
                    vous devez envoyer une facture avec
                    l’ensemble des prestations réalisées,
                    en format PDF et selon le modèle que
                    nous vous avons transmis lors de la
                    contractualisation, à <a href="mailto:fournisseurs@happytal.com" target="_blank">fournisseurs@happytal.com</a>.
                    Vous recevez ensuite un virement sous
                    20 jours.<br /><br />
                    Exemple : Pour les prestations réalisées
                    au mois de septembre, vous envoyez une
                    facture avant le 3 octobre. Vous recevrez
                    un virement avant fin octobre.<br /><br />
                    <b>/!\\ Attention, les factures sont
                    traitées par un logiciel. Toute facture
                    qui n’est pas en format PDF et qui ne
                    respecte pas le modèle fourni ne pourra
                    être traitée.</b>`
                },
                {
                    question: `Quels tarifs utiliser pour
                    ma facturation ?`,
                    answer: `Pour les auto-entrepreneurs
                    non assujettis à la TVA : merci d’utiliser
                    les tarifs envoyés lors de votre email de
                    contractualisation et de vous référer à
                    la colonne « <b>Prix d’achat HT – Votre
                    rémunération</b> ».<br />
                    Pour les personnes assujettis à la TVA :
                    merci de vous référez aux tarifs négociés
                    lors de vos échanges avec l’équipe des
                    partenariats et d’appliquer la remise
                    accordée au moment de la facturation.`
                },
                {
                    question: `Je n’ai pas reçu mon
                    paiement, que dois-je faire ?`,
                    answer: `Vérifiez d’abord que vous avez bien
                    envoyé votre facture en PDF par mail à
                    <a href="mailto:fournisseurs@happytal.com" target="_blank">fournisseurs@happytal.com</a>
                    et selon le modèle transmis.<br />
                    Si tel est le cas, merci d’envoyer un email
                    à <a href="mailto:fournisseurs@happytal.com" target="_blank">fournisseurs@happytal.com</a>,
                    en précisant la date d’envoi de la facture,
                    votre nom, votre prénom, le nom de votre
                    société et le nom de l’établissement dans
                    lequel vous intervenez.`
                },
                {
                    question: `J’ai un problème technique
                    et/ou de factures. A qui dois-je
                    m’adresser ?`,
                    answer: `Si les réponses précédentes et
                    la vidéo tutoriel ne répondent pas à
                    votre question, merci de contacter
                    <a href="mailto:partenariats@happytal.com" target="_blank">partenariats@happytal.com</a> et de
                    préciser dans l’objet du mail HAPPYDOM.`
                },
                {
                    question: `J’ai un problème technique et/ou
                    de factures. A qui dois-je m’adresser ?`,
                    answer: `Dans le cas d’un problème avec
                    un résident (ex : le résident a eu un
                    problème durant la prestation), pensez
                    à contacter directement l’établissement.`
                },
                {
                    question: `Vidéos de présentations happydom`,
                    answer: `- Tutoriel :
                    <a href="https://www.youtube.com/watch?v=WAFU0fTr8wI" target="_blank">https://www.youtube.com/watch?v=WAFU0fTr8wI</a>`
                }
            ]
        },
        getFaqItems () {
            const role = this.getUserRole()
            switch (role) {
                case Roles.EhpadManager:
                case Roles.EhpadEmployee:
                    return this.getAGroupFaqItems()
                case Roles.Tutor:
                case Roles.Family:
                case Roles.HappytalEmployee:
                case Roles.HappytalSupport:
                    return this.getBGroupFaqItems()
                case Roles.Provider:
                case Roles.HappytalProvider:
                    return this.getCGroupFaqItems()
                default:
                    return []
            }
        }
    }
}
</script>

<style lang="scss">
#faq-page {
    .faq-bg {
        background-color: #fff;
    }
}
</style>
